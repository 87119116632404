import React from 'react'
import { Helmet } from "react-helmet";

import Img from '../img/home-page-banner-1.webp'

export default function SliderSec() {
  return (
    <>
    <Helmet>
        <title>Tax Consultant in USA | US Tax Expert</title>
        <meta name="description" content="Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!" />
        <meta name="keywords" content="Tax Consultant in USA, US Tax Expert,tax consultant business in usa,Tax Filing in usa" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Tax Consultant in USA | US Tax Expert" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" /> 
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"  />
        <meta name="twitter:site" content="@sbataxconsult" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tax Consultant in USA | US Tax Expert" />
        <meta name="twitter:image" content="https://shorturl.at/3vrRs" />
        <meta name="https://sbataxconsultants.com/" content="industrial tax consultant in usa" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Tax Consultant in USA | US Tax Expert" />
        <link rel="canonical" href="https://sbataxconsultants.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tax Consultant in USA | US Tax Expert" />
        <meta property="og:description" content="Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!" />
        <meta property="og:url" content="https://sbataxconsultants.com/" />
        <meta property="og:site_name" content="Tax Consultant in USA | US Tax Expert" />
        <meta property="og:image" content="https://shorturl.at/3vrRs" />
        <meta property="og:image:secure_url " content="https://shorturl.at/3vrRs" />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:type" content="image/webp" />
       
      </Helmet>

      <section className='bg-video'>
        
        {/* <video autoPlay muted="muted" loop poster="" className="video">
            <source src={video} type="video/mp4" className='video' />
        </video> */}
        <img src={Img} className='img-fluid' alt='Banner Img' />
        <div className='SliderText'>
            <h1 className='border-bottom border-2 mb-3 pb-3'>Welcome to <br></br><span className='fw-bold primary-text'>SBA Tax Consultants</span></h1>
            <h4 className='fw-bold mb-5'>With our knowledgeable staff and commitment to excellence, we strive to provide top-notch service to individuals and businesses alike.</h4>
            <a className='btn btn-danger' href="tel:+1 469 722 5480"><span className='h4 primary-text px-4'><i className="bi bi-telephone-forward"></i> Call Us </span></a>&nbsp;&nbsp;&nbsp;<a className='btn btn-danger' href="./contact-us"><span className='h4 px-2 primary-text'>Get in Touch</span></a>
        </div>
        
        {/* <div id="demo" className='container d-none'>
            <div className="col-md-12 position-relative">                
                <div className='SliderText'>
                    
                    <p className=''>With our knowledgeable staff and commitment to excellence, we strive to provide top-notch service to individuals and businesses alike.</p>
                    <a href="#contactUs"><button className='btn btn-success px-5 py-3'>Get In Touch ! <i className="bi bi-envelope"></i></button></a>
                </div>
            </div>
        </div>  */}
      
    </section>
    </>
    
  )
}
