import React, { useState, useRef, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import Promise from 'bluebird';


const Inc_Sticky_Form = () => {
    
  const sheetKey = '1E5-4JIiCMCFQkE9_JGdIT7H6FqR_Yvkjo-DuknInIOk';
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const form = useRef();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    Mobile: '',
    email: '',
    subject: '',
    message: '',
    recaptchaValue: null, // Initialize the reCAPTCHA value
  });
  
  const credentials = require('./client_secret.json');

  // Google Sheets spreadsheet key
  const doc = new GoogleSpreadsheet(sheetKey);

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {
    // Load the reCAPTCHA script when the component mounts
    if (showRecaptcha) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      document.head.appendChild(script);
    }
  }, [showRecaptcha]);



  const [error, setError] = useState(null);


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formData.recaptchaValue) {
      setError('Please complete the reCAPTCHA verification.');
      return;
    }

    // Reset the error state
    setError(null);

    // Save data to Google Sheets
    try {
      const doc = new GoogleSpreadsheet(sheetKey);
      await doc.useServiceAccountAuth(credentials);
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0];
      const promisifiedAddRow = Promise.promisify(sheet.addRow, { context: sheet });
      await promisifiedAddRow({
        Timestamp: new Date(),
        // ... add more fields as needed
        ...formData,
      });
      console.log('Data saved to Google Sheets');
    }  catch (error) {
      console.error('Error saving data to Google Sheets', error);
    }

    // Send email using EmailJS
    try {
      const emailResult = await emailjs.sendForm(
        'service_8p2ngld',
        'template_zw4a1dn',
        form.current,
        '3SvwfiC7gDlSrHSvx'
      );
      navigate('/thank-you');
      //alert('Email sent successfully', emailResult);
    } catch (error) {
      console.error('Error sending email', error);
    }

    setFormData({
      first_name: '',
      last_name: '',
      Mobile: '',
      email: '',
      subject: '',
      message: '',
    });
  };



  

  const handleRecaptchaChange = (value) => {
    // Update the form state with the reCAPTCHA value
    setFormData({
      ...formData,
      recaptchaValue: value,
    });

    // Clear the error when the user completes the reCAPTCHA
    setError(null);
  };

  const handleInputChange = (e) => {
    // Update the form data as the user types
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (!showRecaptcha) {
      setShowRecaptcha(true);
    }

  };
  


  return (  
          
       
        <div className="sticky" id='Cont_form'>
             
                    <small>Have Some Questions?</small>
                    <h2 className='fw-bold primary-text mb-4'>Let's Chat</h2>

                    
                <form className='row' autoComplete='off' ref={form} onSubmit={handleFormSubmit}>
                    {/* Your other form fields */}

                    <div className="col-md-6 form-group "> 
                        <label>First Name<span className='text-danger'>*</span></label>                                   
                        <input value={formData.first_name} onChange={handleInputChange} type="text" name='first_name' onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control ff" required />
                    </div>

                    <div className="col-md-6 form-group "> 
                      <label>Last Name<span className='text-danger'>*</span></label>                                         
                        <input value={formData.last_name} onChange={handleInputChange} type="text" name='last_name'  onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control ff" required />
                    </div>

                    <div className="col-md-6 form-group "> 
                        <label>Mobile<span className='text-danger'>*</span></label>                                         
                        <input value={formData.Mobile} onChange={handleInputChange} type="text" maxLength={13} minLength={10} name='Mobile'  className="ff form-control" onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '');}} required />
                    </div>

                    <div className="col-md-6 form-group "> 
                        <label>Email<span className='text-danger'>*</span></label>                                         
                        <input value={formData.email} onChange={handleInputChange} type="email" name='email'  className="form-control ff" required />
                    </div>

                    <div className="col-md-12 form-group ">    
                      <label>Subject<span className='text-danger'>*</span></label>                                      
                        <input value={formData.subject} onChange={handleInputChange} type="text" name='subject' className="form-control ff" required />
                    </div>

                    <div className="col-md-12 form-group "> 
                        <label>Message<span className='text-danger'>*</span></label>                                         
                        <textarea value={formData.message} onChange={handleInputChange} className='form-control mm' rows={2} name='message' ></textarea>
                    </div>
                    
                    {/* Main--  6LeeczkpAAAAAHhwk2zz9moWNOLDU_Ji61Lh1sd4   localhost--  6Lcl0ygoAAAAAHKrsouKfVZMhLnkt6qZe2ecLy9x */}

                    {showRecaptcha && (
                    <>
                        <ReCAPTCHA
                        sitekey="6LeeczkpAAAAAHhwk2zz9moWNOLDU_Ji61Lh1sd4"
                        onChange={handleRecaptchaChange}
                        theme="light"
                        />
                        {/* Display the error message if reCAPTCHA is not completed */}
                        {/* Add your error state logic here */}
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </>
                    )}
                    
                    <div className="col-md-12 form-group mt-2 mb-2">
                        <button type='submit' value="Send" className="btn btn-success px-5 mx-auto text-center">Submit</button>
                    </div>            
                </form>
             
            
        </div>
     
  );
};

export default Inc_Sticky_Form;
