import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/NRI/NRI-Service.jpg'
import {Helmet} from "react-helmet";
export default function Accounting() {
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1300) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1300) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title>NRI Tax Services | NRI Tax Consultant Services</title>
        <meta name="description" content="NRI services in the USA with SBA Tax Consultants. From income tax returns to expert consulting, we cater to all your NRI tax needs." />
        <meta name="keywords" content="NRI Services, NRI Income Tax Return, NRI Tax Consultant Services, NRI Services in usa." />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="NRI Tax Services | NRI Tax Consultant Services" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />

        <meta name="zipcode" content="75247" /> 
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="NRI Tax Services | NRI Tax Consultant Services" />
        <meta name="twitter:image" content="https://sbataxconsultants.com/static/media/NRI-Service.a3279e24c108e50f28d4.jpg"  />
        <meta name="https://sbataxconsultants.com/nri-services" content="NRI Services in usa." />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="NRI Services | NRI Tax Consultant Services" type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/nri-services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NRI Services | NRI Tax Consultant Services" />
        <meta property="og:description" content="NRI services in the USA with SBA Tax Consultants. From income tax returns to expert consulting, we cater to all your NRI tax needs." />
        <meta property="og:url" content="https://sbataxconsultants.com/nri-services" />
        <meta property="og:site_name" content="NRI Services | NRI Tax Consultant Services"/>
        <meta property="og:image" content="https://sbataxconsultants.com/static/media/NRI-Service.a3279e24c108e50f28d4.jpg"  />
        <meta property="og:image:secure_url " content="https://sbataxconsultants.com/static/media/NRI-Service.a3279e24c108e50f28d4.jpg" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:type" content="image/jpg" />

    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          {/* <div className="container">            
              <div className='bannerText'>
                  <h1>NRI Service</h1>
                  <p className=''>We (Certified Public Accountants) in the USA can provide various financial and tax services for Non-Resident Indians (NRIs) who have financial interests or ties to the United States.</p>
              </div>            
          </div> */}
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>NRI services</h1>
                <h6 className=''>We (Certified Public Accountants) in the USA can provide various financial and tax services for Non-Resident Indians (NRIs) who have financial interests or ties to the United States.</h6>
               
                <h5 className='py-2 primary-text'>Some of the services We offer to NRIs include:</h5>                
           
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Compliance for NRIs:</h5>   
                    <p>We assist NRIs in understanding their U.S. tax obligations, including filing requirements, tax residency status, and reporting income earned in the United States. They help navigate complex tax laws to ensure compliance and minimize tax liabilities.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>International Tax Planning:</h5>   
                    <p>We provide strategic tax planning advice to NRIs, helping them optimize their financial situation across borders. This includes guidance on tax-efficient investment strategies, estate planning, and minimizing double taxation.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Foreign Investment and Asset Management:</h5>   
                    <p>We offer advice on managing investments and assets in the United States, including real estate holdings, stocks, retirement accounts, and other financial instruments. They ensure compliance with U.S. tax laws related to these investments.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Treaties and Tax Credits:</h5>   
                    <p>We help NRIs take advantage of tax treaties between the U.S. and their home country to reduce tax liabilities. They also assist in claiming tax credits or deductions available to NRIs to avoid double taxation.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>IRS Representation and Compliance:</h5>   
                    <p>We can represent NRIs in dealings with the IRS, including audits, tax notices, or resolving tax issues related to their U.S. interests. They ensure that NRIs fulfill their U.S. tax obligations and address any tax-related concerns.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Estate and Gift Tax Planning:</h5>   
                    <p>We assist NRIs in estate planning and gift tax strategies, considering U.S. tax laws governing estate transfers and minimizing tax implications for beneficiaries.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Retirement and Social Security Benefits:</h5>   
                    <p>We advise NRIs on U.S. retirement plans, such as IRAs (Individual Retirement Accounts) or Social Security benefits, explaining eligibility criteria, taxation, and distribution rules.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Cross-Border Financial Planning:</h5>   
                    <p>We provide holistic financial planning advice considering both U.S. and foreign financial matters, addressing complexities arising from cross-border investments, income, and assets.</p>
                </div>
        
                <div className="">
                    <p className=''>We specializing in international tax matters and serving NRI clients possess expertise in navigating the complexities of U.S. tax laws concerning non-residents. They play a crucial role in ensuring compliance, minimizing tax burdens, and providing tailored financial advice to meet the unique needs of NRIs.</p>                
                    
                </div>
            </div>
            <div className="col-md-4 fixedElement" style={styles}>
                <Inc_Sticky_Form />
            </div>
        
        </div>
        </div>
    </section>
    </>
  )
}
