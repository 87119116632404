import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/taxFiling/Tax-Filling.jpg'
import {Helmet} from "react-helmet";
export default function TaxFiling() {
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1400) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1400) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title> Tax Filing Services in USA  | US Tax filing consultants</title>
        <meta name="description" content="SBA Tax Consultants was providing US Tax Filing Services. We can file your taxes without hurting your money goals. For more info Contact SBA Tax Consultants." />
        <meta name="keywords" content="US Tax Filing, Tax Filing in USA, Tax filing consultants, Tax Filing Services in usa." />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Tax Filing Services in USA  | US Tax filing consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />        
        <meta name="zipcode" content="75247" /> 
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"  />
        <meta name="twitter:site" content="@sbataxconsult" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tax Filing Services in USA  | US Tax filing consultants" />
        <meta name="twitter:image" content="https://sbataxconsultants.com/static/media/Tax-Filling.b7e0842f5f4ee002623f.jpg"  />
        <meta name="https://sbataxconsultants.com/tax-filing" content="Tax Filing in USA" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Tax Filing Services in USA  | US Tax filing consultants"  type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/tax-filing" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="US Tax Filing Services | US Tax filing consultants" />
        <meta property="og:description" content="SBA Tax Consultants was providing US Tax Filing Services. We can file your taxes without hurting your money goals. For more info Contact SBA Tax Consultants." />
        <meta property="og:url" content="https://sbataxconsultants.com/tax-filing" />
        <meta property="og:site_name" content="Tax Filing Services in USA  | US Tax filing consultants"/>
        <meta property="og:image" content="https://sbataxconsultants.com/static/media/Tax-Filling.b7e0842f5f4ee002623f.jpg" />
        <meta property="og:image:secure_url " content="https://sbataxconsultants.com/static/media/Tax-Filling.b7e0842f5f4ee002623f.jpg" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:type" content="image/jpg" />
    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          <div className="container">            
              {/* <div className='bannerText'>
                  <h1>Tax Filing </h1>
                  <p className=''>CPAs offer a comprehensive range of services related to tax filing in the USA. These services encompass various aspects of tax preparation, planning, compliance, and representation. </p>
              </div>             */}
          </div>
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>Tax Filing Services</h1>
                <h6 className=''>We offer a comprehensive range of services related to tax filing in the USA. These services encompass various aspects of tax preparation, planning, compliance, and representation. </h6>
               
                <h5 className='py-2 primary-text'>Here's an overview of CPA services related to tax filing:</h5>              
                
            
                <div className="border-bottom">
                    <h5 className='primary-text'> Tax Preparation:</h5>   
                    <p>We prepare and file various tax returns for individuals, businesses (including corporations, partnerships, and LLCs), estates, trusts, and nonprofits. They gather relevant financial information, apply deductions and credits, and ensure accurate and timely filing.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Planning and Advisory:</h5>   
                    <p> We provide proactive tax planning strategies to minimize tax liabilities. They analyze financial situations, recommend tax-saving opportunities, and offer guidance on decisions that impact taxes, such as investments, retirement planning, and business transactions.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Compliance and Regulatory Compliance:</h5>   
                    <p>We ensure that clients comply with federal, state, and local tax laws and regulations. They stay updated on tax code changes, identify potential risks or issues, and help businesses maintain compliance to avoid penalties.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Estate and Trust Taxation:</h5>   
                    <p> We assist in estate and trust tax planning and filing, helping to minimize tax obligations and ensure proper compliance with estate tax laws.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'> Representation and Negotiation:</h5>   
                    <p>We represent clients during tax audits, examinations, or disputes with tax authorities. They interact with the IRS or state tax agencies on behalf of clients, providing support and negotiation to resolve tax-related issues.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax Credits and Incentives: </h5>   
                    <p>We identify eligible tax credits and incentives that individuals and businesses can leverage to reduce their tax burden. They help clients take advantage of available credits, deductions, and incentives.</p>
                </div>
       
                <div className="border-bottom">
                    <h5 className='primary-text'>Multi-State and International Taxation:</h5>   
                    <p>For businesses operating in multiple states or internationally, We navigate complex tax regulations across different jurisdictions. They ensure compliance with state and international tax laws, addressing issues related to nexus, withholding, and reporting requirements.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Tax-Exempt Organizations: </h5>   
                    <p>We assist nonprofit organizations with tax-exempt status applications (such as 501(c)(3)) and provide guidance on compliance with tax laws governing nonprofit entities.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Estimated Tax Payments and Compliance: </h5>   
                    <p>We help individuals and businesses calculate and make timely estimated tax payments to meet quarterly tax obligations, avoiding underpayment penalties.</p>
                </div>
       
                <div className="">                
                    <p>We play a crucial role in managing various tax-related matters, offering expertise, guidance, and ensuring that individuals and businesses meet their tax obligations while optimizing tax efficiency within the bounds of the law.</p>
                </div>
            </div>
            <div className="col-md-4 fixedElement" style={styles}>
                <Inc_Sticky_Form />
            </div>
        </div>
      </div>
    </section>
    </>
  )
}