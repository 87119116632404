import './App.css';
import{BrowserRouter, Routes, Route} from 'react-router-dom';

// import Navbar from './components/Navbar';
import SliderSec from './components/home/SliderSec';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';


import Contact from './components/contact/Contact';
import About from './components/about/About';
import NavbarD from './components/NavbarD';
import AboutH from './components/home/AboutH';
import ServiceSlider from './components/home/ServiceSlider';
import Industry from './components/home/Industry';
import WhyChooseUs from './components/home/WhyChooseUs';
import Testimonial from './components/home/Testimonial';
import Corp from './components/corporate-registration/Corp';
import SalesTax from './components/SalesTax/SalesTax';
import Licenses from './components/Licenses/Licenses';
// import BankAccountAssistance from './components/Bank-account-assistance/BankAccountAssistance';
import Bookkeeping from './components/bookkeeping/Bookkeeping';
import TaxFiling from './components/TaxFiling/TaxFiling';
import TaxConsultation from './components/TaxConsult/TaxConsultation';
import PayrollSer from './components/PayrollSer/PayrollSer';
import Insurance from './components/insurance/Insurance';
import FourOneKAssistance from './components/401K/FourOneKAssistance';
import Accounting from './components/Accounting/Accounting';
// import NotarySol from './components/NotarySol/NotarySol';
import NriService from './components/NRI/NriService';
import Irs from './components/ItIrs/Irs';
// import GroupofCompany from './components/GroupofCompany';
import PrivacyPolicy from './components/Priavcy/PrivacyPolicy';
import Terms from './components/Terms/Terms';
import ThankYou from './components/ThankYou';
import IndividualTaxFiling from './components/individual-tax-filing/IndividualTaxFiling';
import ITRTaxFiling from './components/itr-tax-filing/ITRTaxFiling';
import New from './components/new-landing/TypesOfPermitInUSA';
import TypesOfPermitInUSA from './components/new-landing/TypesOfPermitInUSA';
import AdmendmentsAndCorrectionChargesInUsa from './components/new-landing/AdmendmentsAndCorrectionChargesInUsa';
import NewCorporationInTheUSA from './components/new-landing/NewCorporationInTheUSA';
import FIRSTDBAFilingWithCorporationUSA from './components/new-landing/FIRSTDBAFilingWithCorporationUSA';
import SecondDBARegistrationUSA from './components/new-landing/SecondDBARegistrationUSA';
import SalesTaxSolutionUSA from './components/new-landing/SalesTaxSolutionUSA';
import TobaccoLicensesInUSA from './components/new-landing/TobaccoLicensesInUSA';
import TexasAlcoholicBeverageCommissionLicenseInUSA from './components/new-landing/TexasAlcoholicBeverageCommissionLicenseInUSA';
import TCEQ from './components/new-landing/TCEQ';
import WMC from './components/new-landing/WMC';
import MSB from './components/new-landing/MSB';
import FSAT from './components/new-landing/FSAT';
import HPBU from './components/new-landing/HPBU';
import OCUR from './components/new-landing/OCUR';
import FPLU from './components/new-landing/FPLU';
import LBLU from './components/new-landing/LBLU';





function App() {
  return (
      
      <BrowserRouter>     
        <NavbarD></NavbarD>
        {/* <Navbar/> */}
        <section className='height-100'></section>
        <Routes>
          <Route exact path='/' element={
            <>
                <SliderSec/>                    
                <AboutH></AboutH>
                <ServiceSlider/>
                <Industry></Industry>
                <WhyChooseUs></WhyChooseUs>
                <Testimonial></Testimonial>
                <ContactForm></ContactForm>
            </>
          } />
          
          <Route path='about-us' element={
            <>
              <About></About>
              {/* <GroupofCompany></GroupofCompany> */}
              
            </>
          } /> 

          <Route path='corporate-registration' element={
            <>
              <Corp></Corp> 
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='sales-tax' element={
            <>
              <SalesTax></SalesTax> 
              <ServiceSlider/> 
              
            </>
          } /> 

          <Route path='license' element={
            <>
              <Licenses></Licenses>
              <ServiceSlider/> 
              
            </>
          } /> 
          
          <Route path='bookkeeping-and-business-consulting' element={
            <>
              <Bookkeeping></Bookkeeping>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='tax-filing' element={
            <>
              <TaxFiling></TaxFiling>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='tax-consultation' element={
            <>
              <TaxConsultation></TaxConsultation>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='payroll-services' element={
            <>
              <PayrollSer></PayrollSer>
              <ServiceSlider/> 
              
            </>
          } />
          
          <Route path='insurance' element={
            <>
              <Insurance></Insurance>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='401k-assistance' element={
            <>
              <FourOneKAssistance></FourOneKAssistance>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='accounting' element={
            <>
              <Accounting></Accounting>
              <ServiceSlider/> 
              
            </>
          } />

          

          <Route path='irs-related-works' element={
            <>
              <Irs></Irs>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='nri-services' element={
            <>
              <NriService></NriService>
              <ServiceSlider/> 
              
            </>
          } />

          <Route path='privacy-policy' element={
            <>
              <PrivacyPolicy></PrivacyPolicy>
            </>
          } />

          <Route path='terms-of-service' element={
            <>
              <Terms></Terms>
            </>
          } />


          
          
          
          <Route path='contact-us' element={
            <>
              <section className='bg-about'>
                <Contact/>
              </section>
              
            </>
          } /> 

          <Route path='thank-you' element={
            <>
              <ThankYou></ThankYou>
            </>
          } /> 

          <Route path='tax-filing-deadline' element={
            <>
              <IndividualTaxFiling />
            </>
          } /> 

        <Route path='ITR-Tax-Filing' element={
            <>
              <ITRTaxFiling></ITRTaxFiling>
            </>
          } /> 
          <Route path='types-of-permit-in-usa' element={
            <>
              <TypesOfPermitInUSA></TypesOfPermitInUSA>
            </>
          } />

          <Route path='admendments-and-correction-charges-in-usa' element={
            <>
              <AdmendmentsAndCorrectionChargesInUsa></AdmendmentsAndCorrectionChargesInUsa>
            </>
          } /> 

          <Route path='new-corporation-in-the-usa' element={
            <>
              <NewCorporationInTheUSA></NewCorporationInTheUSA>
            </>
          } />

          <Route path='first-dba-filing-with-corporation-usa' element={
            <>
              <FIRSTDBAFilingWithCorporationUSA></FIRSTDBAFilingWithCorporationUSA>
            </>
          } /> 

          <Route path='second-dba-registration-usa' element={
            <>
              <SecondDBARegistrationUSA></SecondDBARegistrationUSA>
            </>
          } /> 

          <Route path='sales-tax-solution-usa' element={
            <>
              <SalesTaxSolutionUSA></SalesTaxSolutionUSA>
            </>
          } /> 

          <Route path='tobacco-licenses-in-usa' element={
            <>
              <TobaccoLicensesInUSA></TobaccoLicensesInUSA>
            </>
          } /> 

          <Route path='texas-alcoholic-beverage-commission-license-in-usa' element={
            <>
              <TexasAlcoholicBeverageCommissionLicenseInUSA></TexasAlcoholicBeverageCommissionLicenseInUSA>
            </>
          } /> 

          <Route path='texas-commission-on-environmental-quality-regulation-in-usa' element={
            <>
              <TCEQ></TCEQ>
            </>
          } /> 

          <Route path='weight-and-measurement-certificate-usa' element={
            <>
              <WMC></WMC>
            </>
          } /> 

          <Route path='money-services-business-license-usa' element={
            <>
              <MSB></MSB>
            </>
          } /> 

          <Route path='food-stamps-application-texas' element={
            <>
              <FSAT></FSAT>
            </>
          } />

          <Route path='health-permit-for-businesses-usa' element={
            <>
              <HPBU></HPBU>
            </>
          } /> 

          <Route path='occupancy-certificate-usa-requirements' element={
            <>
              <OCUR></OCUR>
            </>
          } />

          <Route path='food-permit-license-usa' element={
            <>
              <FPLU></FPLU>
            </>
          } /> 
          <Route path='lottery-business-license-usa' element={
            <>
              <LBLU></LBLU>
            </>
          } /> 






















          

          
          




          
            

             
          
          
          
        </Routes>
        
           
        <Footer></Footer> 
          
       </BrowserRouter> 
  );
}

export default App;
