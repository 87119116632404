
import React, { useState, useEffect } from 'react';
import topImage from '../img/corporateRegis/Corporate-Registration.jpg';
import { Helmet } from "react-helmet";
import Inc_Sticky_Form from '../Inc_Sticky_Form';



export default function Corp() {

  const [styles, setStyles] = useState({
    position: 'static',
    top: '0px',
    width: '100%',
    visibility: 'visible'
  });

  useEffect(() => {
    const handleScroll = () => {
      const mq = window.matchMedia("(min-width: 768px)");

      if (mq.matches) {
        const sTop = window.scrollY;

        if (sTop > 400 && sTop <= 1400) {
          setStyles({
            position: 'fixed',
            top: '150px',
            width: '30%',
            right:'3%',
            visibility: 'visible'            
          });
        }

        if (sTop >1400) {
          setStyles({
            position: 'static',
            top: '150px',
            width: '23%',
            visibility: 'hidden'
          });
        }

        if (sTop < 350) {
          setStyles({
            position: 'static',
            top: '0px',
            width: '100%',
            visibility: 'visible'
          });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    // const [isSectionVisible, setIsSectionVisible] = useState(false);
    // const sectionRef = useRef(null);

    // useIntersectionObserver(
    //     sectionRef,
    //     () => {
    //       console.log('Section entered');
    //       setIsSectionVisible(true);
    //     },
    //     () => {
    //       console.log('Section left');
    //       setIsSectionVisible(false);
    //     }
    //   );

  return (
    <>
      <Helmet>
        <title>Corporate Registrations in USA | Company Registration in USA</title>
        <meta name="description" content=" SBA Tax Consultant Serves Corporation Registrations, We are here for your Company Registration in USA. You can contact SBA Tax Consultants for more information." />
        <meta name="keywords" content="Corporate Registration in usa, company registration, register company, company registration in USA, LLC registration in USA, LLC company registration in USA." />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Corporate Registrations | Company Registration" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />        
        <meta name="zipcode" content="75247" /> 
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"  />
        <meta name="twitter:site" content="@sbataxconsult" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Corporate Registrations | Company Registration" />
        <meta name="twitter:image" content="https://sbataxconsultants.com/static/media/Corporate-Registration.29c960bfacc6c9c2ebfa.jpg" />
        <meta name="https://sbataxconsultants.com/corporate-registration" content="Corporate Registration in usa." />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Corporate Registrations | Company Registration" type="application/opensearchdescription+xml" /> 
        <link rel="canonical" href="https://sbataxconsultants.com/corporate-registration"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Corporate Registrations | Company Registration"/>
        <meta property="og:description" content="SBA Tax Consultant Serves Corporation Registrations, We are here for your Company Registration in USA. You can contact SBA Tax Consultants for more information."/>
        <meta property="og:url" content="https://sbataxconsultants.com/corporate-registration"/>
        <meta property="og:site_name" content="Corporate Registrations | Company Registration"/>
        <meta property="og:image" content="https://sbataxconsultants.com/static/media/Corporate-Registration.29c960bfacc6c9c2ebfa.jpg" />
        <meta property="og:image:secure_url " content="https://sbataxconsultants.com/static/media/Corporate-Registration.29c960bfacc6c9c2ebfa.jpg"/>
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:type" content="image/jpg" />
      
      </Helmet>
      <section>
        <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
        <div className="container">
          {/* <div className='bannerText'>
            <h1>Corporate Registration</h1>
            <p className=''>We at SBA Tax Consultants provide you services related to Corporate filings, formation and meet all yours compliance needs.</p>
          </div> */}
        </div>
      </section>
      <section className='py-5'>
        <div className="container ">
          <div className="row align-items-center position-relative">
            <div className="col-md-8">
              <h1 className='h2 primary-text '>Corporate Registrations</h1>
              <h5>In the United States, there are several types of corporation registrations that an Individuals can form.</h5>
              <h4 className='py-5 primary-text'> The most common types are:</h4>
              <div className="border-bottom">
                <h5 className='primary-text'>C-Corporation (C-Corp):</h5>
                <p><strong>Requirements:</strong> To form a C-Corp, you need to file Articles of Incorporation with the state, appoint directors, issue stock, and comply with various state-specific regulations.</p>
                <p><strong>Benefits:</strong> Limited liability protection for shareholders, perpetual existence, ability to raise capital through the sale of stock, potential tax advantages like deductible business expenses.</p>
              </div>
              <div className="border-bottom">
                <h5 className='primary-text'>S-Corporation (S-Corp):</h5>
                <p><strong>Requirements:</strong> To qualify as an S-Corp, the firm must first establish itself as a C-Corp and then file Form 2553 with the IRS to elect S-Corp status. It must meet specific IRS criteria, such as having no more than 100 shareholders and meeting certain shareholder qualifications.</p>
                <p><strong>Benefits:</strong> Pass-through taxation, limited liability for shareholders, potential tax savings by avoiding double taxation (on corporate and personal levels).</p>
              </div>
              <div className="border-bottom">
                <h5 className='primary-text'>Limited Liability Company (LLC):</h5>
                <p><strong>Requirements:</strong> Forming an LLC involves filing Articles of Organization with the state, creating an operating agreement, and complying with state regulations for annual filings and taxes.</p>
                <p><strong>Benefits:</strong> Limited liability protection, flexible management structure, pass-through taxation, fewer formalities compared to corporations.</p>
              </div>
              <div className="">
                <h5 className='fw-bold'>Each type of corporation registration offers different advantages and considerations:</h5>
                <ul>
                  <li><strong>Liability Protection:</strong> Corporations (both C-Corps and S-Corps) and LLCs provide limited liability protection, shielding personal assets from business liabilities.</li>
                  <li><strong>Taxation:</strong> C-Corps are subject to double taxation (once at the corporate level and again at the individual level when dividends are distributed to shareholders). S-Corps and LLCs are pass-through entities, meaning profits and losses pass through to the owners' personal tax returns.</li>
                  <li><strong>Management Structure:</strong> C-Corps and S-Corps have more rigid management structures with directors, officers, and shareholders. LLCs offer flexibility in management and ownership structures.</li>
                  <li><strong>Regulatory Requirements:</strong> C-Corps have more formalities, such as holding regular board meetings and keeping detailed records. S-Corps and LLCs have fewer formalities in terms of meetings and record-keeping.</li>
                  <li><strong>Ownership and Shareholders:</strong> C-Corps can have an unlimited number of shareholders, whereas S-Corps are limited to 100 shareholders with specific eligibility criteria. LLCs can have a flexible ownership structure with different classes of membership interests.</li>
                </ul>
                <p>When choosing a business structure, it's crucial to consider factors like tax implications, liability protection, governance requirements, and the firm's long-term goals. Consulting us can help you to make informed decisions about the most suitable entity type for your specific needs and circumstances.</p>
              </div>
            </div>
            {/* <div className={`col-md-4 ${isSectionVisible ? 'sticky-form-active' : ''}`}>
              <Inc_Sticky_Form />
            </div> */}

            <div className="col-md-4 fixedElement" style={styles}>
              <Inc_Sticky_Form />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
