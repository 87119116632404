import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import successStoryBg from '../img/success-story-bg-1.webp';
import userImg from '../img/user-img.avif';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };
  

const Testimonial = () => {
    const settings = {
      dots: false,
      infinite: true,
      autoplaySpeed: 2000,
      autoplay:true,
      slidesToShow: 2, // Number of items to show at a time
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
        <>
             <section className='px-5 py-5' id='SuccessSpotLight'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 mb-4 text-center">
                            <h3 className='fw-bold '>Success Spotlights</h3>
                            <p>Don't just take our word for it, see what our clients had said about their experiences with SBA Tax Consultants:</p>
                        </div>
                    </div>
                
                <Slider {...settings}>
                    
                    <div className='p-3 mx-2 '>
                        <div className="position-relative">                            
                            <img src={successStoryBg} className='img-fluid '  alt="service" />
                            <div className='row testimonialText'>
                              <div className="col-9">
                                <p className='Text text-white'>"I cannot recommend SBA Tax Consultants enough. Their team is knowledgeable, professional, and truly cares about helping their clients achieve financial success."</p>
                                <p className='Name'>Emily P</p>
                              </div>
                              <div className="col-3">
                                <img src={userImg} alt="testimonial" className='img-fluid rounded-circle' />
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-3 mx-2 '>
                      <div className="position-relative">                            
                          <img src={successStoryBg} className='img-fluid '  alt="service" />
                          <div className='row testimonialText'>
                            <div className="col-9">
                              <p className='Text text-white'>"SBA Tax Consultants has been an invaluable asset to my business. They handle all of my accounting needs and have saved me so much time and stress. Thank you!"</p>
                              <p className='Name'>Michael L.</p>                              
                            </div>
                            <div className="col-3">
                              <img src={userImg} alt="testimonial" className='img-fluid rounded-circle' />
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className='p-3 mx-2 '>
                      <div className="position-relative">                            
                        <img src={successStoryBg} className='img-fluid '  alt="service" />
                        <div className='row testimonialText'>
                          <div className="col-9">
                            <p className='Text text-white'>"As a freelancer, I always dread tax season. But with SBA Tax Consultants, it's no longer a headache. They make the process seamless and efficient."</p>
                            <p className='Name'>Karen W</p>
                          </div>
                          <div className="col-3">
                            <img src={userImg} alt="testimonial" className='img-fluid rounded-circle' />
                          </div>
                        </div>
                    </div>
                    </div>
                </Slider>
                </div>
            </section>
            
        </>
    );
  };
  
  export default Testimonial;
  

