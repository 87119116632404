import React from 'react';
import comingSoon from '../img/why-choose-SBA-Tax.webp'

export default function WhyChooseUs() {
  return (
    <section className='py-5 primary-bg'>
        <div className="container" id="whyChooseUs">
            <div className="row align-items-center justify-content-center">
                <div className="col-md-8 mb-5">
                    <h2 className='text-white fw-bold text-center'>
                        Why Choose Us ?
                    </h2>
                    <p className='text-center text-white'>Our team at SBA Tax Consultants strives to provide the best service possible to our clients. We understand that taxes and finances can be daunting, which is why we are committed to making the process as smooth and stress-free as possible. With our expertise and personalized approach, we have built a reputation for delivering exceptional results and exceeding our clients' expectations.</p>
                    {/* <h6 className='text-center text-white'>Choosing SBA tax consultation means choosing a partner invested in your success.</h6> */}
                </div>
            </div>
            <div className="row align-items-center justify-content-center">

                <div className="col-md-4 justify-content-center align-items-center" >
                    <div className="col-md-12 mb-5">
                        <h5 className="text-white">Expertise You Can Trust</h5>
                        <p className="text-white">When it comes to SBA tax consultation, trust is paramount. </p>                   
                    </div>
                    

                    <div className="col-md-12 mb-5">
                        <h5 className="text-white">Tailored Solutions for Your Business</h5>
                        <p className="text-white">We recognize that each business is unique, and cookie-cutter solutions don't suffice when it comes to tax matters.</p>
                    </div>
                    

                    <div className="col-md-12 mb-5">
                        <h5 className="text-white">Navigating Complex SBA Tax Codes</h5>
                        <p className="text-white">SBA tax codes can be intricate and challenging to decipher. Our team is well-versed in the nuances of SBA regulations, staying abreast of updates and changes.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <img src={comingSoon} alt="update soon"  className='img-fluid rounded mb-4'/>
                </div>
                <div className="col-md-4 justify-content-center align-items-center">                
                    


                    <div className="col-md-12 mb-5">
                        <h5 className="text-white">Proactive Planning for Financial Success</h5>
                        <p className="text-white">Our approach to SBA tax consultation goes beyond mere compliance; we are committed to proactive planning for your financial success. </p>
                    </div>
                    


                    <div className="col-md-12 mb-5">
                        <h5 className="text-white">Responsive and Client-Focused Service</h5>
                        <p className="text-white">We prioritize client satisfaction and are dedicated to providing responsive and client-focused service.</p>
                    </div>
                    


                    <div className="col-md-12 mb-5">
                        <h5 className="text-white">Transparent and Competitive Pricing</h5>
                        <p className="text-white">Our fee structures are clear and competitive, ensuring that you receive exceptional value for your investment. </p>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </section>
  )
}
