import React from 'react'
import topImage from './img/thank-you.jpg'
export default function ThankYou() {
  return (
    
    <>
    <section>
        <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
        <div className="container">            
            <div className='bannerText'>
                <h1 className=''>Thank you for getting in touch with us !</h1>                
                <p>Your message has been received, and we will get back to you as soon as possible.</p>
                
            </div>            
        </div>
        
    </section>

    <section>
        <div className="container py-5">
            <div className='row py-5'>
                <div className="col-md-12 text-center">
                    <h2>Explore More</h2>
                    <a href='./'><button className="btn-danger btn">Go Back to Home Page</button></a>
                </div>
            </div>
        </div>
    </section>

      
    </>
    
  )
}
