import React from 'react'
import topImage from '../img/about-us.webp'
import mission from '../img/mission.jpg'
import vision from '../img/vision.jpg'
import obj from '../img/objectives.jpg'
// import user from '../img/user.avif'
import akMerchant from '../img/ak-merchant.png'
import BharathSir from '../img/bharat-sir.jpeg'
import {Helmet} from "react-helmet";
export default function About() {
  return (
    <>

        <Helmet>
            <title>Tax Consultants in USA | Expert Tax Filing Services</title>
            <meta name="description" content="Discover SBA Tax Consultants in the USA for expert tax filing services. Maximize your returns with top-rated professionals." />
            <meta name="keywords" content="Tax Filing in USA, Tax consultant in usa, US Tax Filing Services in usa." />
            <meta name="robots" content="index, follow" />
            <meta name="YahooSeeker" content="Index,Follow" />
            <meta name="Googlebot" content="Index,Follow" />
            <meta name="bingbot" content="index, follow" />
            <meta name="classification" content="Tax Consultants in USA | Expert Tax Filing Services" />
            <meta name="allow-search" content="Yes" />
            <meta name="distribution" content="global" />
            <meta name="Content-Language" content="EN" />
            <meta name="geo.region" content="US-TX" />
            <meta name="geo.placename" content="Dallas" />            
            <meta name="zipcode" content="75247" /> 
            <meta name="expires" content="never" />
            <meta name="language" content="English" />
            <meta name="author" content="SBA Tax Consultants" />
            <meta name="rating" content="General" />
            <meta name="audience" content="All" />
            <meta name="publisher" content="SBA Tax Consultants" />
            <meta name="city" content="Dallas" />
            <meta name="State" content="Texas" />
            <meta property="og:image:type" content="image/webp" />
            <meta name="twitter:creator" content="@sbataxconsult"  />
            <meta name="twitter:site" content="@sbataxconsult" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Tax Consultants in USA | Expert Tax Filing Services" />
            <meta name="twitter:image" content="https://sbataxconsultants.com/static/media/about-us.3b7cc7437fe599fe1f59.webp" />
            <meta name="https://sbataxconsultants.com/about-us" content="US Tax Filing Services in usa." />
            <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Tax Consultants in USA | Expert Tax Filing Services" type="application/opensearchdescription+xml" /> 
            <link rel="canonical" href="https://sbataxconsultants.com/about-us"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Tax Consultants in USA | Expert Tax Filing Services"/>
            <meta property="og: description" content=" Discover SBA Tax Consultants in the USA for expert tax filing services. Maximize your returns with top-rated professionals."/>
            <meta property="og:url" content="https://sbataxconsultants.com/about-us"/>
            <meta property="og:site_name" content="Tax Consultants in USA | Expert Tax Filing Services Services"/> 
            <meta property="og:image" content="https://sbataxconsultants.com/static/media/about-us.3b7cc7437fe599fe1f59.webp" />
            <meta property="og:image:secure_url " content="https://sbataxconsultants.com/static/media/about-us.3b7cc7437fe599fe1f59.webp" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="548" />
            <meta property="og:image:type" content="image/webp" />
        </Helmet>

      <section>
            <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
            <div className="container">            
                {/* <div className='bannerText'>
                <h1>About Us</h1>
                <p>"Tax consultants fuel growth by optimizing financial strategies, navigating regulations, and maximizing incentives for sustained success and business expansion."</p>
                </div>             */}
            </div>
            
      </section>
      
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
            
            <div className="col-md-8">
                <h1 className='py-3 h1 primary-text fw-bold'>
                Top Tax Consultants
                </h1>
                <p className=''>SBA Tax Consultants specialize in unraveling complexities, ensuring your business maximizes deductions and remains compliant. Trust our team of professionals and CPA's to optimize your financial strategy, providing clarity and peace of mind in the ever-evolving tax landscape.</p>

                
            </div>
            
        </div>

        <div className="row align-items-center justify-content-center mt-4">
            <div className="col-md-8">
                <p>Our team ensure you maximize deductions, remain compliant, and gain clarity in the ever-evolving tax landscape. Trust us as your partner in financial success, providing expert guidance tailored to your unique needs. With SBA Tax Consultant, empower your business to thrive in the dynamic world of small business taxation. As Top Tax Consultants, we are dedicated to delivering the highest level of service and expertise to help you navigate your financial journey.</p>
            </div>            
        </div>

        <div className="row align-items-center justify-content-center pt-4 " id='About'>
            
            <div className="col-md-8 pb-4 border-bottom">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-vision-tab" data-bs-toggle="tab" data-bs-target="#nav-vision" type="button" role="tab" aria-controls="nav-vision" aria-selected="false"><span className='fw-bold'>Vision</span></button>
                        <button className="nav-link " id="mission-tab" data-bs-toggle="tab" data-bs-target="#mission" type="button" role="tab" aria-controls="mission" aria-selected="true"><span className='fw-bold'>Mission</span></button>
                        
                        <button className="nav-link" id="nav-objectives-tab" data-bs-toggle="tab" data-bs-target="#nav-objectives" type="button" role="tab" aria-controls="nav-objectives" aria-selected="false"><span className='fw-bold'>Objectives</span></button>                        
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade   py-4" id="mission" role="tabpanel" aria-labelledby="mission-tab" tabindex="0">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <img src={mission} alt="Mission" className='img-fluid' />
                            </div>
                            <div className="col-md-7">
                                <p className=''>At SBA Tax Consultant, our mission is to empower small businesses by offering expert guidance in navigating the intricacies of tax regulations. We strive to be the trusted partner that businesses rely on for strategic tax planning and compliance, fostering financial resilience and growth.</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="tab-pane fade show active py-4" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab" tabindex="0">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <img src={vision} alt="Mission" className='img-fluid' />
                            </div>
                            <div className="col-md-7">
                                <p>Our vision is to be a catalyst for small business success, revolutionizing the way entrepreneurs approach their financial responsibilities. We aspire to create a landscape where businesses not only survive but thrive, leveraging our expertise to optimize tax strategies and contribute to their long-term sustainability.<br></br> With Expert Tax Filing Services by SBA Tax Consultants, we are committed to guiding you through every step of your financial journey.</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="tab-pane fade py-4" id="nav-objectives" role="tabpanel" aria-labelledby="nav-objectives-tab" tabindex="0">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <img src={obj} alt="Mission" className='img-fluid' />
                            </div>
                            <div className="col-md-7">
                                <ul>
                                    <li><strong>Expert Guidance:</strong> Provide unparalleled expertise to small businesses, ensuring they understand and adhere to SBA tax regulations.</li>
                                    <li><strong>Strategic Tax Planning:</strong> Develop customized tax plans that align with each client's unique business goals, maximizing deductions and minimizing liabilities.</li>
                                    <li><strong>Compliance Assurance:</strong> Ensure businesses remain compliant with evolving SBA tax laws, reducing the risk of audits and penalties.</li>
                                    <li><strong>Financial Empowerment:</strong> Empower clients with the knowledge and tools to make informed financial decisions, promoting fiscal responsibility and long-term success.</li>
                                    <li><strong>Innovation in Solutions:</strong> Stay at the forefront of tax-related innovations, continuously updating our strategies to adapt to the ever-changing business and regulatory landscape.</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>                    
                </div>
            </div>
        </div>

        <div className="row align-items-center justify-content-center pt-5" id='FounderSec'>
            
            <div className="col-md-8">
                <h3 className='fw-bold primary-text text-center mb-5'>Founders</h3>
                <div className="row align-items-center border-bottom">
                    <div className="col-md-5">
                        <img src={akMerchant} alt="img" className='img-fluid rounded-circle border shadow' />
                    </div>
                    <div className="col-md-7">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
                            <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
                        </svg>
                        <p>Get addicted towards your passion in such a way, that life's unpredictable turning challenges will become your stepping stones towards your success.
                            More than two decades of consistent performance has helped us to achieve the right place. 
                        </p>
                        <h5 className='pt-3'>Akshay Merchant</h5>
                        <h6 className='primary-text'>Chief Operations Officer</h6>
                        <h6 className='mt-4'>Qualication: </h6>
                        <h6 className='secondary-text'> CPA , CMA , EA , CSCA , CA (India) , FIII , MCom & BMS</h6>
                       
                        <p>A successful individual, adept in analytical and creative thinking, navigates challenges with strategic precision. Their motivation and self-awareness serve as catalysts, driving not only personal growth but inspiring teams to excel.</p>
                        <div className='d-flex'>
                            <a href="https://www.linkedin.com/in/ca-akshay-merchant/"><i className="bi bi-linkedin primary-text h3"></i></a>&nbsp;&nbsp;&nbsp;
                            {/* <a href="#" className='mt-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                                </svg>
                            </a> */}
                        </div>
                        
                    </div>
                    <div className="col-md-12 my-3">
                        <p> Akshay Merchant is having 10+ years of industry experience, serving various clients and has worked in listed companies in India. And he is also the youngest professor in 7 colleges. In the last 9 years, he taught more than 6000+ students. Currently he is teaching CMA, CPA, EA and ACCA students. </p>
                        <p>His simple communication and explaining skills has helped many clients to optimise business finance planning and also helped students clearing competitive exams.</p>
                        <p>His financial planning and analysis achieved remarkable milestones that helped to solve many compliance problems. His expertise steered businesses to fiscal success, ensuring sustainable growth.</p>
                        
                        
                        <h6 className='fw-bold py-3'>Area Of Focus</h6>
                        <div className='d-flex'>
                            
                            
                            <button className='btn btn-danger rounded-pill'>Inclusive Growth</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill'>Leadership</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill'>Innovation</button>&nbsp;&nbsp;

                        </div>


                    </div>
                </div>

                <div className="row align-items-center border-bottom pb-1 mt-5">
                    
                    <div className="col-md-7">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
                            <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
                        </svg>
                        <p>Innovation drives our vision. With dedication and expertise, we pave the path to excellence in every venture. Over the last decade, his innovative business aspects helped many people to achieve their professions.</p>
                        <h5 className='pt-3'>Dr. Bharath Kumar Kakkireni</h5>
                        <h6 className='primary-text'>Chief Technical Advisor</h6>
                        <h6 className='mt-4'>Qualication: </h6>
                        <h6 className='secondary-text'> B.Pharm, MSM, MS, USA</h6>
                        
                        <p>A sharp focus businessman Dr. K.Bharath Kumar is the Chairman and CEO of KBK Group of companies. From Hospital to IT, his passion and effort brings him to lead the success. With the right attitude, you can achieve success in any field.</p>
                        <div className=''>
                            <a href="https://www.linkedin.com/in/bharathkumarkakkireni/"><i className="bi bi-linkedin primary-text h3"></i></a>&nbsp;&nbsp;&nbsp;
                            
                        </div>
                        
                    </div>
                    <div className="col-md-5">
                        <img src={BharathSir} alt="img" className='img-fluid rounded-circle' />
                    </div>
                    <div className="col-md-12 my-3">
                        <p>His vast experience in the industry of IT, and hospitals, serving various clients in tax filing. Also, he has served as a responsible partner in a variety of industries. The KBK Group's IT and tax filing services helped many businesses reach their goal with seamless financial compliance and maximum returns.</p>
                        <p>In addition to being a successful entrepreneur, he is an experienced professional who has founded and now oversees a successful software company. With his assistance in IT and tax filing, many businesses are able to grow and improve.</p>
                        <p>He is an important person in transforming the KBK Group into an established name. His work style and leadership gave the finest results that go in terms of client satisfaction. </p>
                        <h6>His Awards And Rewards :</h6>
                        <ul>
                            <li><p>Selected for the prestigious Forbes Business Council for 2024.</p></li>
                            <li><p>The Rashtriya Gaurav Award from the Senior VP of Telangana Congress on 26th December 2023</p></li>
                            <li><p>The prestigious Sanjeeva Ratna Purskar from the Government of Telangana on World Health Day 2023.</p></li>
                            <li><p>His name was included on the top 10 most dynamic entrepreneurs of 2023 by "The Indian Alert and Digi Sharks Communications.</p></li>
                            <li><p>On November 04, 2022, he got the Pride of Hyderabad 2022 Award.</p></li>
                        </ul>
                        <p>His innovative ideas helped many companies to make the right business plan with financial analysis.</p>
                        <h6 className='fw-bold py-3'>Area Of Focus</h6>
                        <div className=''>
                            <button className='btn btn-danger rounded-pill mb-2'>Technology</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill mb-2'>Innovation</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill mb-2'>Inclusive Growth</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill mb-2'>Leadership</button>

                        </div>
                        


                    </div>
                </div>
            </div>

        </div>

        <div className="row align-items-center justify-content-center pt-5 d-none" id='About'>
            
            <div className="col-md-8 pb-4 border-bottom">
                <h3 className='fw-bold primary-text text-center pb-3'>Code of Ethics</h3>
                
                <div className="row align-item-center py-5">
                    <div className="col-md-3">
                        <h5>Content here</h5>
                    </div>
                    <div className="col-md-9 border-start">
                        <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for</p>
                    </div>
                </div>

                <div className="row align-item-center py-5">
                    <div className="col-md-3">
                        <h5>Content here</h5>
                    </div>
                    <div className="col-md-9 border-start">
                        <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for</p>
                    </div>
                </div>


                <div className="row align-item-center py-5">
                    <div className="col-md-3">
                        <h5>Content here</h5>
                    </div>
                    <div className="col-md-9 border-start">
                        <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for</p>
                    </div>
                </div>

            </div>
        </div>

        

      </div>
    </section>
    </>
  )
}
