import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import cor from '../img/service/cor.avif';
import cor from '../img/service/Corporate-registrations.webp';
// import salesTax from '../img/service/sales-tax.avif';
import salesTax from '../img/service/Sales-tax.webp';
// import lic from '../img/service/license.avif';
import lic from '../img/service/Licences.webp';
// import bankAcc from '../img/service/bank-ac.avif';
import bankAcc from '../img/service/Bank-Account-Assistance.webp';
// import bk from '../img/service/bookkeeping.avif';
import bk from '../img/service/Bokkeping.webp';
// import taxFiling from '../img/service/tax-filing.avif';
import taxFiling from '../img/service/Tax-Filling.webp';
// import taxConsult from '../img/service/tax-consult.avif';
import taxConsult from '../img/service/tax-consultations.webp';
// import payroll from '../img/service/payroll.avif';
import payroll from '../img/service/payroll-services.webp';
// import insu from '../img/service/insurance.avif';
import insu from '../img/service/insurance.webp';
// import fouroneK from '../img/service/fouronek.avif';
import fouroneK from '../img/service/401k-assistance.webp';
// import accounting from '../img/service/accounting.avif';
import accounting from '../img/service/Accounting.webp';
// import notSol from '../img/service/notary.avif';
import notSol from '../img/service/Notary-sollutions.webp';
// import itinIR from '../img/service/itin-irs.avif';
import itinIR from '../img/service/ITIN-IRT.webp';
// import nriS from '../img/service/nri.avif';
import nriS from '../img/service/NRI-Services.webp';
import { Link } from 'react-router-dom';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };
  

const ServiceSlider = () => {
    const settings = {
      dots: false,
      infinite: true,
      autoplaySpeed: 2000,
      autoplay:true,
      slidesToShow: 6, // Number of items to show at a time
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
        <>
             <section className='serviceSec px-5 py-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className='fw-bold text-center pb-3'>Our Services</h3>
                        </div>
                    </div>
                </div>
                <Slider {...settings}>
                    
                    <div className='p-2 mx-2 '>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/corporate-registration">
                            <img src={cor} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Corporate Registrations</h5>
                            </Link>
                        </div>
                    </div>
                    <div className='p-2 mx-2 '>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/sales-tax">
                            <img src={salesTax} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Sales Tax</h5>
                            </Link>
                        </div>
                    </div>
                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/license">
                            <img src={lic} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Licenses</h5>
                            </Link>
                        </div>
                    </div>
                    
                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/bookkeeping-and-business-consulting">
                            <img src={bk} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Bookkeeping</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/tax-filing">
                            <img src={taxFiling} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Tax Filing</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/tax-consultation">
                            <img src={taxConsult} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Tax Consultations</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/payroll-services">
                            <img src={payroll} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Payroll Services</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/insurance">
                            <img src={insu} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Insurance</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/401k-assistance">
                            <img src={fouroneK} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>401K Assistance</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/accounting">
                            <img src={accounting} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Accounting</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/notary-solutions">
                            <img src={notSol} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>Notary Solutions</h5>
                            </Link>
                        </div>
                    </div>

                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/irs-related-works">
                            <img src={itinIR} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>ITIN/IRS Related Works</h5>
                            </Link>
                        </div>
                    </div>
                    
                    <div className='p-2 mx-2'>
                        <div className="text-center service-Box">
                            <Link to="https://sbataxconsultants.com/nri-services">
                            <img src={nriS} className='img-fluid '  alt="service" />
                            <h5 className='text-dark'>NRI Services</h5>
                            </Link>
                        </div>
                    </div>
                    
                </Slider>
            </section>
            
        </>
    );
  };
  
  export default ServiceSlider;
  

