import React from 'react'

import { Link } from 'react-router-dom'
import whatsappIcon from './img/icons/icons8-whatsapp.gif'

export default function Footer() {
  return (
    <>
    <footer className='bg-dark border-top border-bottom py-4'>
      <div className="container pt-3">
        <div className="row">
          <div className="col-md-4">
            <h4 className='secondary-text'>USA Office</h4>                
            <p className='text-white'><i className="bi bi-geo-alt"></i> 8500 N Stemmons Fwy, Dallas, Texas 75247</p>
            <p><a className='text-white' href="tel: +1 469 722 5480"><i className="bi bi-telephone-forward"></i> +1 469 722 5480</a></p>
            
            <hr></hr>
            <h4 className='secondary-text hhideForIn'>India Office</h4>                
            <p className='text-white hhideForIn'><i className="bi bi-geo-alt"></i> H-No:2-1-8/4/1, NR.Suite 2A, Saraswathi Colony, Uppal, Hyderabad, Telangana 500039</p>
            <p><a className='text-white hhideForIn' href="tel:+91 99676 23999"><i className="bi bi-telephone-forward"></i> +91 99676 23999</a></p>
            <hr></hr>
            
          </div>
          <div className="col-md-3">
            <h4 className='secondary-text'>Services</h4>
            <ul className='text-dec-none footerLinks'>
              <li><Link  to="./corporate-registration">Corporation Registration</Link></li>
              <li><Link  to="./sales-tax">Sales Tax</Link></li>
              <li><Link  to="./license">License</Link></li>
              {/* <li><Link  to="./bank-account-assistance">Bank Account Assistance</Link></li> */}
              <li><Link  to="./bookkeeping-and-business-consulting">Bookkeeping & Business Consulting</Link></li>
              <li><Link  to="./tax-filing">Tax Filing</Link></li>
              <li><Link  to="./tax-consultation">Tax Consultations</Link></li>
              
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className='secondary-text m-none'>&nbsp;</h4>
            <ul className='text-dec-none footerLinks'>  
              <li><Link  to="./payroll-services">Payroll Services </Link></li>
              <li><Link  to="./insurance">Insurance </Link></li>
              <li><Link  to="./401k-assistance">401K Assistance</Link></li>            
              <li><Link  to="./accounting">Accounting </Link></li>
              <li><Link  to="./notary-solutions">Notary Solutions</Link></li>
              <li><Link  to="./irs-related-works">ITIN/IRS Related works</Link></li>
              <li><Link  to="./nri-services">NRI Services</Link></li>
            </ul>
          
          </div>
          <div className="col-md-2">
            <h4 className='secondary-text'>Quick Links</h4>
            <ul className='text-dec-none footerLinks'>
                <li><Link to="about-us">About Us</Link></li>
                <li><Link to="contact-us">Contact Us</Link></li>
                <li><Link to="privacy-policy">Privacy Policy</Link></li>
                <li><Link to="terms-of-service">Terms of Use</Link></li>  
              
            </ul>
          </div>
          
        </div>
        <div className='row'>
          <div className="col-md-5 reachUs">
            <h5 className='secondary-text'>Reach Us:</h5>
            <p><a className='text-white' href='mailto: contact@sbataxconsultants.com'><i className="bi bi-envelope-open"></i> contact@sbataxconsultants.com</a></p>
          </div>
          
          <div className="col-md-7">
            <ul className='list-style-none float-end'>
              <li className='px-2'><a target='_blank' href='https://www.facebook.com/sbataxconsultants' ><span className="bi bi-facebook text-white h4"> </span></a></li>
              <li className='px-2'><a target='_blank' href='https://www.linkedin.com/company/sba-tax-consultants-llc/?viewAsMember=true' ><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                </svg></a></li>
              <li className='px-2'>
                <a target='_blank' href='https://twitter.com/sbataxconsult' className='text-white'>
                  <img alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAsMCwyNTYsMjU2IgpzdHlsZT0iZmlsbDojMDAwMDAwOyI+CjxnIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxnIHRyYW5zZm9ybT0ic2NhbGUoMTAuNjY2NjcsMTAuNjY2NjcpIj48cGF0aCBkPSJNMi44NjcxOSwzbDYuODY5MTQsOS44MTgzNmwtNy4wMDE5NSw4LjE4MTY0aDIuNjQ2NDhsNS41MzkwNiwtNi40OTAyM2w0LjU0MTAyLDYuNDkwMjNoNS45MTAxNmwtNy4xOTcyNywtMTAuMzAyNzNsNi41NzAzMSwtNy42OTcyN2gtMi42MDU0N2wtNS4xNDI1OCw2LjAwOTc3bC00LjE5NzI3LC02LjAwOTc3eiI+PC9wYXRoPjwvZz48L2c+Cjwvc3ZnPg=="/>
                </a>
              </li>
              <li className='px-2'><a target='_blank' href='http://www.youtube.com/@SBATaxConsultant' ><span className="bi bi-youtube h4"> </span></a></li>
              <li className='px-2'><a target='_blank' href='https://www.instagram.com/sba_taxconsultants/' ><span className="instagram h4"><span className="bi bi-instagram"></span></span></a></li>
            </ul>
          </div>
        </div>
      </div>
        <div className="container pt-3 d-none">
          <div className="row">
            <div className="col-md-8 mt-3">
              
              <ul className='text-dec-none ps-0' id='QuickLinks'>
                <li><h5 className='mb-0'>Quick Links | </h5></li>
                
                <li><Link to="about-us"><i className="bi bi-link-45deg"></i>&nbsp;About</Link></li>
                <li><Link to="contact-us"><i className="bi bi-link-45deg"></i>&nbsp;Contact</Link></li>
                <li><Link to="privacy-policy"><i className="bi bi-link-45deg"></i>&nbsp;Privacy Policy</Link></li>
                <li><Link to="terms-of-service"><i className="bi bi-link-45deg"></i>&nbsp;Terms of Use</Link></li>               
              </ul>
            </div>
            
            <div className="col-md-4 footer-right">
              
              <div className="row">
                <div className="col-md-12">
                  <h6 className='float-end'>Stay Connected</h6>
                </div>
                <div className="col-md-12">                 
                  <ul className='list-style-none float-end'>
                    <li className='px-2'><a href='https://www.facebook.com/sbataxconsultants' ><span className="bi bi-facebook h4"> </span></a></li>
                    <li className='px-2'><a href='https://www.linkedin.com/company/sba-tax-consultants-llc/?viewAsMember=true' ><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                      <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                      </svg></a></li>
                    <li className='px-2'><a href='https://twitter.com/' ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                    </svg></a></li>
                    <li className='px-2'><a href='http://www.youtube.com/@SBATaxConsultant' ><span className="bi bi-youtube h4"> </span></a></li>
                    <li className='px-2'><a href='https://www.instagram.com/' ><span className="instagram h4"><span className="bi bi-instagram"></span></span></a></li>
                  </ul>
                </div>
                {/* <div className="col-md-12">
                  <h6 className='float-end'><a className='text-decoration-none secondary-text' href='tel: +1 98765 98798'><i className="bi bi-telephone-forward"></i>&nbsp;&nbsp; +1 98765 98798 </a></h6>
                </div>
                <div className="col-md-12">
                  <h6 className='float-end'><a className='text-decoration-none secondary-text' href='mailto: mail@domain.com'><i className="bi bi-envelope"></i>&nbsp;&nbsp;mail@domain.com </a></h6>
                </div> */}
              </div>                
              
            </div>
          </div>
          
        </div>
    </footer>
    <section className='bg-dark'>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className='mt-3 text-light'>Copyright @ 2023 SBA Tax Consultants.</p>
          </div>
          <div className="col-md-6">
            <a target='_blank' href='https://kbkbusinesssolutions.com'><p className='text-light mt-3 float-end'>Designed and Developed by <span className='text-danger'>KBK Business Solutions</span></p></a>
          </div>
          
        </div>
      </div>
    </section>

    <div className='whatsappChat'>
      
          <a title='Whatsapp Chat Support' href='https://api.whatsapp.com/send?phone=+1%20469%20722%205480&text=Hi%20I%20have%20query%20about%20%20'>
            <img src={whatsappIcon} alt="" className="img-fluid rounded-circle" />
          </a>
      
    </div>
    </>
  )
}
