import { useState, useEffect  } from "react";
import React from "react";
import logo from "../logo.webp";
import { Link, useLocation   } from "react-router-dom";
export default function NavbarD() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation();

    // Function to toggle the dropdown
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    // Function to close the dropdown
    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    // Function to close the dropdown on mobile devices
    const closeDropdownOnMobile = () => {
        if (window.innerWidth < 768) {
        closeDropdown();
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);
    return (
        <>  
           
           <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-white">
                <div className="container d-flex">
                    <a className="navbar-brand" href="/">
                        <img src={logo} className="img-fluid" alt="sba tax consultant logo"/>
                    </a>
                    <button className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#main_nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={toggleDropdown}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse${isDropdownOpen ? ' show' : ''}`} id="main_nav">
                        <ul className="navbar-nav  ms-auto">

                            <li className="nav-item"><span onClick={closeDropdownOnMobile} className="nav-link"><Link to="/">Home</Link></span></li>
                            
                            <li className="nav-item"><span onClick={closeDropdownOnMobile} className="nav-link"><Link to="./about-us"> About Us </Link></span></li>
                            
                            <li className="nav-item dropdown has-megamenu">
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Services  </a>
                                <div className="dropdown-menu megamenu" role="menu">
                                    <div className="row g-3 pb-3">
                                        <div className="col-md-12 ps-4 mb-0">
                                            <h6 className="title mb-0">What We Do</h6>
                                        </div>
                                        <div className="col-lg-4 col-6 ps-4">
                                            <div className="col-megamenu">
                                                
                                                <ul className="list-unstyled">
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./corporate-registration">Corporation Registration</Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./sales-tax">Sales Tax</Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./license">License</Link></li>
                                                    {/* <li><Link  onClick={closeDropdownOnMobile} to="./bank-account-assistance">Bank Account Assistance</Link></li> */}
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./bookkeeping-and-business-consulting">Bookkeeping & Business Consulting</Link></li>
                                                </ul>
                                            </div> 
                                        </div>
                                        <div className="col-lg-4 col-6 ps-4">
                                            <div className="col-megamenu">                                               
                                                <ul className="list-unstyled">
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./tax-filing">Tax Filing</Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./tax-consultation">Tax Consultation</Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./payroll-services">Payroll Service </Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./insurance">Insurance </Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./401k-assistance">401K Assistance</Link></li>
                                                </ul>
                                            </div> 
                                        </div>
                                        <div className="col-lg-4 col-6 ps-4">
                                            <div className="col-megamenu">                                                
                                                <ul className="list-unstyled">
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./accounting">Accounting </Link></li>
                                                    {/* <li><Link  onClick={closeDropdownOnMobile} to="./notary-solutions">Notary Solutions</Link></li> */}
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./irs-related-works">ITIN/IRS Related works</Link></li>
                                                    <li><Link  onClick={closeDropdownOnMobile} to="./nri-services">NRI Service</Link></li>
                                                </ul>
                                            </div> 
                                        </div>
                                        
                                          
                                        
                                    </div>
                                </div> 
                            </li>
                            <li className="nav-item"><span className="nav-link"><a  onClick={closeDropdownOnMobile} href="https://sbataxconsultants.com/blog/"> Blog </a></span></li>
                            <li className="nav-item"><span className="nav-link"><Link  onClick={closeDropdownOnMobile} to="./contact-us"> Contact Us </Link></span></li>
                            {/* <li className="nav-item"><span className="nav-link"><Link  onClick={closeDropdownOnMobile} to="./"> Upload Files </Link></span></li> */}
                        </ul>
                        
                    </div> 
                </div> 
                </nav>

            {/* <nav className="navbar navbar-expand-lg p-0" id="ftco-navbar">
                <div className="container d-flex align-items-center">
                    <a className="navbar-brand p-0" href="/">
                        <img src={logo} className="img-fluid" alt="SBA" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="oi oi-menu"></span> Menu
                    </button>

                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav ">
                            <li className="nav-item">
                                <a className="nav-link " href="#">
                                    About
                                </a>
                                
                            </li>
                            <li className="nav-item dropdown has-megamenu">
                                <a className="nav-link dropdown-toggle" href="#" aria-expanded={isDropdownOpen} onClick={toggleDropdown}>
                                    Services
                                </a>
                                <div className={`dropdown-menu megamenu p-0 animated-fast fadeInUp ${isDropdownOpen ? "show" : ""}`} role="menu" data-bs-popper="none">
                                    <div className="inside-top-grid position-relative">
                                        <div className="row g-3 ">
                                            <div className="col-lg-12">
                                                <div className="col-megamenu">
                                                    <h6 className="title">What We Do</h6>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <Link to="/corporate-registration">
                                                                    Corporation Registrations
                                                                    </Link>                                                                    
                                                                </li>
                                                                <li>
                                                                    <Link to="/sales-tax">
                                                                        Sales Tax
                                                                    </Link>                                                                    
                                                                </li>
                                                                <li>
                                                                    <Link to="/license">
                                                                        Licenses
                                                                    </Link>                                                                    
                                                                </li>
                                                                <li>
                                                                    <Link to="/bank-account-assistance">
                                                                    Bank Account Assistance
                                                                    </Link>                                                                    
                                                                </li>
                                                                <li>
                                                                    <Link to="/bookkeeping">
                                                                    Book Keeping 
                                                                    </Link>                                                                    
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <a href="">Existing Product Assistance</a>                                                                    
                                                                </li>
                                                                <li>
                                                                    <a href="">UI/UX and Application Design</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>                                    
                                </div>
                            </li>
                            
                            <li className="nav-item dropdown has-megamenu">
                                <a className="nav-link dropdown-toggle" href="#" aria-expanded="false">
                                    Contact Us
                                </a>
                                
                            </li>

                            <li className="nav-item dropdown has-megamenu">
                                <a className="nav-link dropdown-toggle" href="#" aria-expanded="false">
                                    Upload Files
                                </a>
                                
                            </li>
                            
                        </ul>
                        
                        
                        
                    </div>
                </div>
            </nav> */}
        </>
    );
}
