import React from 'react'
import akMerchant from './img/ak-merchant.png'
export default function About_akshay() {
  return (
    <>
    
    <section className='aboutFounder'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center pt-5" id='FounderSec'>
            
            <div className="col-md-12">
                <h2 className='fw-bold h1 primary-text text-center mb-5'>Founder</h2>
                <div className="row align-items-center border-bottom">
                    <div className="col-md-4">
                        <img src={akMerchant} alt="img" className='img-fluid border rounded-circle shadow aboutAkhsy' />
                    </div>
                    <div className="col-md-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
                            <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
                        </svg>
                        <p>Get addicted towards your passion in such a way, that life's unpredictable turning challenges will become your stepping stones towards your success.
                            More than two decades of consistent performance has helped us to achieve the right place. 
                        </p>
                        <h5 className='pt-3'>Akshay Merchant</h5>
                        <h6 className='primary-text'>Chief Operations Officer</h6>
                        <h6 className='mt-4'>Qualication: </h6>
                        <h6 className='secondary-text'> CPA , CMA , EA , CSCA , CA (India) , FIII , MCom & BMS</h6>
                       
                        <p>A successful individual, adept in analytical and creative thinking, navigates challenges with strategic precision. Their motivation and self-awareness serve as catalysts, driving not only personal growth but inspiring teams to excel.</p>
                        

                        <a href='#Cont_form'><button className="btn btn-alert lt-1 px-5">REQUEST A CALL BACK</button></a>
                        
                    </div>
                    <div className="col-md-12 my-3">
                        <p> Akshay Merchant is having 10+ years of industry experience, serving various clients and has worked in listed companies in India. And he is also the youngest professor in 7 colleges. In the last 9 years, he taught more than 6000+ students. Currently he is teaching CMA, CPA, EA and ACCA students. </p>
                        <p>His simple communication and explaining skills has helped many clients to optimise business finance planning and also helped students clearing competitive exams.</p>
                        <p>His financial planning and analysis achieved remarkable milestones that helped to solve many compliance problems. His expertise steered businesses to fiscal success, ensuring sustainable growth.</p>
                        
                        
                        <h6 className='fw-bold py-3'>Area Of Focus</h6>
                        <div className='d-flex'>                           
                            
                            <button className='btn btn-danger rounded-pill'>Inclusive Growth</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill'>Leadership</button>&nbsp;&nbsp;
                            <button className='btn btn-danger rounded-pill'>Innovation</button>&nbsp;&nbsp;

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    </>
  )
}
